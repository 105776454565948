html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    scroll-behavior: smooth;
    background-color: black; /* Set the background color to black */
    color: white; /* Set the text color to white for visibility */
    display: flex;
    flex-direction: column;
    font-size: 30px
}

.App {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    min-height: 100vh; /* Full viewport height */
    background-color: black; /* Black background */
    color: white; /* White text */
}

.header {
    display: flex;
    flex-direction: row; /* Default layout for PC */
    align-items: center; /* Align items vertically */
    justify-content: flex-start; /* Align items to the start of the container */
    font-family: 'Kanit', sans-serif;
}

/* Responsive styling for mobile devices */
@media (max-width: 768px) {
    .header {
        flex-direction: column; /* Stack items vertically on smaller screens */
        font-size: 20px
    }
}

